import Axios, {AxiosHeaders, AxiosResponse} from "axios"
import {googleLogout} from "@react-oauth/google"
import {fromByteArray} from 'base64-js'
import {Project, StateDetails, Workflow} from "./Workflow"
import {NotificationsAccess} from "../App";

const prefix = process.env.REACT_APP_PREFIX ?? ""

function isLoggedIn(): boolean {
    const token = localStorage.getItem('token')
    return !!token
}

async function login(token: string): Promise<boolean> {
    const resp = await Axios.post<{ token: string }>(prefix + "/a/user/login", {token})
    if (resp.status < 300) {
        localStorage.setItem('token', resp.data.token)
        return true
    } else {
        return false
    }
}

async function loginAs(role: string): Promise<boolean> {
    const token = await post<{token: string}>("user/loginAs?role=" + encodeURIComponent(role), {})
    if (token) {
        localStorage.setItem('token', token.token)
        return true
    } else {
        return false
    }
}

function logout() {
    googleLogout()
    localStorage.removeItem('token')
}

export interface VideoResponse {
    link: string
    page: string
    user: string
    name: string
}

async function getVideo(): Promise<VideoResponse | undefined> {
    const resp = await Axios.get<VideoResponse>(prefix + "/a/video/kitchen", {headers: headers()})
    if (resp.status < 300) {
        return resp.data
    } else {
        return undefined
    }
}

export interface User {
    _id: string,
    name: string,
    image: string,
    roles: Array<string>
    role?: string
    read?: {[k:string]: number}
}

async function getCurrentUser(): Promise<User | undefined> {
    const resp = await Axios.get<User>(prefix + "/a/user", {headers: headers()})
    if (resp.status < 300) {
        return resp.data
    } else {
        return undefined
    }
}

async function getUsers() {
    return get<Array<User>>('users')
}

async function getProject(id: string): Promise<Project | undefined> {
    return get<Project>(`project/${id}`)
}

async function getProjects(search?: string) {
    return get<Array<Project>>(search ? `projects?q=${encodeURIComponent(search)}` : 'projects')
}

async function createProject(title: string, brief: string) {
    return post<Project>(`project`, {title, brief})
}

async function addAttachment(project: string, filename: string, url: string, contentType: string) {
    return post<Project>(`project/${project}/attach`, {filename, url, contentType})
}

interface assignment {
    producer?: string
    voice?: string
    writer?: string
}

async function assign(project: string, assignment: assignment) {
    return post<Project>(`project/${project}/assign`, assignment)
}

async function comment(project: string, text: string) {
    return post<Project>(`project/${project}/comment`, {text})
}

async function uploadFile(project: string, filename: string, contentType: string, data: ArrayBuffer) {
    return post<string>(`project/${project}/upload`, {filename, contentType, data: fromByteArray(new Uint8Array(data))})
}

async function projectStep(project: string, dir: "next" | "prev", state: string, updates: Map<string, any>) {
    return post<Project>(`project/${project}/step`, {state, dir, updates: Object.fromEntries(updates)})
}

async function getWorkflow() {
    const wf = await get<object>('workflow')
    if (wf) {
        const result = new Map<String, {states: Map<string, StateDetails>, users: Map<string, string>}>()
        for (let entry of Object.entries(wf)) {
            result.set(entry[0], {
                states: new Map<string, StateDetails>(Object.entries(entry[1].states)),
                users: new Map<string, string>(Object.entries(entry[1].users))
            })
        }
        return result as Workflow
    }
    return undefined
}

async function updateRead(projectId: string, ts: number) {
    return post<User>(`user/updateRead/${projectId}?ts=${ts}`, {})
}

const network = {
    uploadFile, addAttachment, getUsers, getProject, getProjects, logout, getCurrentUser, createProject, projectStep, isLoggedIn, getVideo, login, loginAs, getWorkflow, assign, comment, updateRead
}
export default network

function headers() : AxiosHeaders {
    const headers = new AxiosHeaders()
    headers.setAuthorization("Bearer " + localStorage.getItem('token'))
    return headers
}

export let version: string|undefined = undefined

async function processResponse<T>(resp: Promise<AxiosResponse<T, any>>): Promise<T |undefined> {
    return resp.then(resp => {
        const serverVer = resp.headers['x-version']
        if (version !== serverVer && serverVer) {
            if (version && NotificationsAccess)
                NotificationsAccess(ns => ns.concat({level: "warning", msg: "New version available, please reload page"}))
            version = serverVer
        }
        if (resp.status === 401) {
            localStorage.removeItem('token')
            window.location.href = "/login"
        } else if (resp.status < 300) {
            return resp.data
        } else {
            return undefined
        }
    })
}

async function get<T>(path: string) {
    return processResponse(Axios.get<T>(prefix + '/a/' + path, {headers: headers(), validateStatus: null}))
}

async function post<T>(path: string, body: any) {
    return processResponse(Axios.post<T>(prefix + '/a/' + path, body, {headers: headers(), validateStatus: null}))
}
