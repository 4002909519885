import { useNavigate } from "react-router-dom";
import "./Dialog.scss";
import Icons from "../util/Icons";
import { Loader } from "../components/Loader";

interface Props {
    className: string
    loading?: boolean
    onClose: () => void
}

export default function Dialog(props: React.PropsWithChildren<Props>) {
    const navigate = useNavigate()
    
    function close() {
        navigate('/')
        props.onClose()
    }

    return <div className={`dialog ${props.className}`} onClick={close}>
        <div className='content' onClick={e => e.stopPropagation()}>
            <div className='close' onClick={close}><Icons.Close/></div>
            {props.children}
        </div>
        <div className={'loader' + (props.loading ? " loading" : "")}><Loader/></div>
    </div>
}