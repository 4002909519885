import {ReactComponent as View} from "../assets/visibility_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Attach} from "../assets/attach_file_add_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Forward} from "../assets/arrow_forward_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Back} from "../assets/arrow_back_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Down} from "../assets/arrow_drop_down_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Up} from "../assets/arrow_drop_up_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Refresh} from "../assets/refresh_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Person} from '../assets/person_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg'
import {ReactComponent as Copy} from '../assets/content_copy_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg'
import {ReactComponent as Comment} from '../assets/comment_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg'
import {ReactComponent as AudioFile} from "../assets/audio_file_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as VideoFile} from "../assets/video_file_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as File} from "../assets/description_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Logo} from "../assets/logo.svg"
import {ReactComponent as Add} from "../assets/post_add_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as Close} from "../assets/cancel_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"

const Icons = {
  View, Attach, Forward, Back, Down, Up, Refresh, Person, Copy, Comment, AudioFile, VideoFile, File, Logo, Add, Close
}

export default Icons;